export const features = {
  print: {
    selectFile: "Select file",
    upload: "Upload",
    uploadSuccess: "Upload successful",
    uploadError: "Upload failed",
    admin: {
      posters: {
        title: "Posters",
        description: "Upload a new standard template to be available to every project",
        format: "Format",
        formats: ["A4", "A3", "A2"],
      },
    },
  },
} as const;
