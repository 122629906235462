export const backend = {
  error: {
    common: {
      unknown: "Ein unbekannter Fehler ist aufgetreten.",
    },
    permission: {
      alreadyExists: "Mit der angegebenen E-Mail Adresse wurde bereits eine Berechtigung vergeben.",
    },
  },
} as const;
