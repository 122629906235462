export const features = {
  print: {
    selectFile: "Datei auswählen",
    upload: "Hochladen",
    uploadSuccess: "Upload erfolgreich",
    uploadError: "Fehler beim Hochladen",
    admin: {
      posters: {
        title: "Plakate",
        description:
          "Lade eine neue Standardvorlage hoch, die jedem Projekt zur Verfügung stehen soll.",
        format: "Format",
        formats: ["A4", "A3", "A2"],
      },
    },
  },
} as const;
