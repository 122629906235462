import { Suspense, lazy } from "react";

import useAuthContext from "@/providers/Auth/AuthProvider";

const PublicRoutes = lazy(() => import("./(public)/routes"));
const PrivateRoutes = lazy(() => import("./(private)/routes"));

const AuthCheck = () => {
  const { me } = useAuthContext();

  if (me?.__typename === "User") {
    return (
      <Suspense fallback={<></>}>
        <PrivateRoutes />
      </Suspense>
    );
  } else {
    return (
      <Suspense fallback={<></>}>
        <PublicRoutes />
      </Suspense>
    );
  }
};

export default AuthCheck;
