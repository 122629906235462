export const designTokens = {
  button: {
    common: {
      fontFamily: "mabry",
      fontWeight: 700,
      fontSize: 18,
      textTransform: "none" as const,
    },
    sizes: {
      small: { height: 40 },
      medium: { height: 48 },
      large: { height: 56 },
    },
  },
  typography: {
    h1: {
      fontFamily: "GT-Alpina",
      fontSize: 160,
      fontWeight: 700,
      lineHeight: 1.0,
    },
    h2: {
      fontFamily: "GT-Alpina",
      fontSize: 96,
      fontWeight: 700,
      lineHeight: 1.0,
    },
    h3: {
      fontFamily: "GT-Alpina",
      fontSize: 72,
      fontWeight: 700,
      lineHeight: 1.0,
    },
    h4: {
      fontFamily: "GT-Alpina",
      fontSize: 64,
      fontWeight: 700,
      lineHeight: 1.0,
    },
    h5: {
      fontFamily: "GT-Alpina",
      fontSize: 48,
      fontWeight: 700,
      lineHeight: 1.0,
    },
    h6: {
      fontFamily: "GT-Alpina",
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1.0,
    },
    copyLargeHero: {
      fontFamily: "mabry",
      fontSize: 18,
      lineHeight: 1.25,
      fontWeight: 700,
    },
    copyLarge: {
      fontFamily: "mabry",
      fontSize: 18,
      lineHeight: 1.25,
      fontWeight: 400,
    },
    copySmallHero: {
      fontFamily: "mabry",
      fontSize: 16,
      lineHeight: 1.25,
      fontWeight: 700,
    },
    copySmall: {
      fontFamily: "mabry",
      fontSize: 16,
      lineHeight: 1.25,
      fontWeight: 400,
    },
  },
  colors: {
    lightBlue: {
      main: "rgb(214, 239, 238)",
      dark: "rgb(200, 229, 228)",
    },
    lavender: {
      main: "rgb(210, 214, 239)",
      dark: "rgb(194, 198, 229)",
    },
    purple: {
      light: "rgb(49, 22, 154)",
      main: "rgb(41, 19, 129)",
      dark: "rgb(32, 15, 103)",
    },
    green: {
      main: "rgb(0, 148, 103)",
      mid: "rgb(7, 81, 59)",
      dark: "rgb(13, 57, 44)",
    },
    grey: {
      mid: "#C2C6E5",
    },
  },
};
