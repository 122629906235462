export type Environments = "production" | "staging" | "development" | "test";
export type Version = `${number}.${number}.${number}`;

export const ENVIRONMENT: Environments = (process.env.REACT_APP_VERCEL_ENV ??
  "development") as Environments;

export const FRONTEND_CARE_URL =
  process.env.REACT_APP_FRONTEND_CARE_URL ?? "http://localhost:52875";
export const FRONTEND_OWNER_URL = process.env.REACT_APP_FRONTEND_URL ?? "http://localhost:3000";
export const FRONTEND_USER_URL = process.env.REACT_APP_FRONTEND_USER_URL ?? "http://localhost:3001";
export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL ?? "http://localhost:5001";
export const BACKEND_WS_URL = process.env.REACT_APP_BACKEND_WS_URL || "ws://localhost:5001/ws";

// Sentry
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_ORGANIZATION = process.env.REACT_APP_SENTRY_ORGANIZATION;
export const SENTRY_PROJECT = process.env.REACT_APP_SENTRY_PROJECT;
export const SENTRY_AUTH_TOKEN = process.env.REACT_APP_SENTRY_AUTH_TOKEN;

export const GIT_COMMIT_REF = process.env.REACT_APP_VERCEL_GIT_COMMIT_REF ?? "develop";
export const GIT_COMMIT_SHA = process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA ?? "-";

export const VERSION = process.env.REACT_APP_VERSION;
export const NAME = "admin";

export const GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "AIzaSyC0A3WE2SS3IaxZ6ipgPU6tltVzp1XAO_M";
export const GOOGLE_STORAGE_PUBLIC_FOLDER =
  process.env.REACT_APP_GOOGLE_STORAGE_PUBLIC_FOLDER ??
  "https://storage.googleapis.com/saferspaces/public";

export const IS_SAFARI =
  navigator.userAgent.includes("Safari") && !navigator.userAgent.includes("Chrome");

export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABSE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
