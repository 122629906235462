export const auth = {
  backgroundImageAlt: "A poster with the saferspaces logo",
  backgroundImageTitle: "Take care, for a <1>safer</1> society",
  backgroundImageDescription: "Be there when help is needed",
  login: {
    hello: "Hello!",
    welcomeMessage:
      "Welcome to the saferspaces administration tool. Log in now if you already have an account.",
    or: "or",
    forgotPassword: "Forgot password?",
    noAccount: "Don't have an account yet?",
    bookADemo: "Book a demo",
    emailInputLabel: "E-Mail address",
    passwordInputLabel: "Password",
    stayLoggedIn: "Stay logged in",
    userNotFound: "No user found with this email address",
    wrongPassword: "Please check your password",
    unknownError: "An unknown error occurred",
    continue: "Continue",
    button: "Login",
    restoreAccount: "Problems logging in?",
  },
  restoreAccount: {
    title: "Hello!",
    description:
      "If you logged in before with one-time code, you can choose a new login method here. Please enter the email address you registered with. We will send you an email with further instructions.",
  },
  register: {
    hello: "Hello!",
    welcomeMessage:
      "Don't have an account yet or signed in with one-time code before? No problem, register now.",
    or: "or",
    alreadyRegistered: "Already registered?",
    goToLogin: "Go to login",
    emailInputLabel: "E-Mail address",
    passwordInputLabel: "Password",
    privacyAndTerms:
      "By registering you agree to our <1>privacy policy</1> and <3>terms of use</3>.",
    button: "Register now",
    unknownError: "An unknown error occurred",
    invalidEmail: "Please enter a valid email address",
    emailAlreadyInUse: "This email address is already in use",
    passwordError: "Please enter a password",
    weakPassword: "The password is too weak",
  },
  resetPassword: {
    hello: "Hello!",
    welcomeMessage:
      "Forgot your password or signed in with one-time code before? No problem, we'll send you an email to set a new password.",
    emailInputLabel: "E-Mail address",
    button: "Reset password",
    backToLogin: "Back to login",
    userNotFound: "No user found with this email address",
    unknownError: "An unknown error occurred",
    passwordNotSet: "No password has been set for this email address",
  },
  delete: {
    hello: "Hello!",
    description:
      "You can have your data deleted here. Please note that we can only delete your data if you provide us with the email address with which you registered. We will contact you by email to confirm your identity.",
    confirmation:
      "Your deletion request has been received. We will take care of it immediately and will get back to you within 30 days at the latest.",
    almost: "Almost there! Next, please enter the code that we sent to ",
    done: " to confirm your identity or use the provided link in the email.",
    rateLimit: "You have reached the maximum number of deletion requests. Please try again later.",
    requestDeletion: "Request deletion",
    verify: "Please verify the code and if you did already register.",
  },
  verifyEmail: {
    title: "For your safety",
    description:
      "Please confirm your email address to continue. We have sent you an email with further instructions to <1>{{email}}</1>.",
    noEmail:
      "If you did not receive an email, please check your spam folder or request a new email.",
    resendEmail: "Resend email",
  },
  setPassword: {
    title: "Hello!",
    description:
      "Would you like to use a password in addition? You currently need this to log in to the app and manage incoming cases. If you already have a password for the app, you must set it here again.",
    passwordInputLabel: "Password",
    submit: "Set password",
    skip: "Skip",
    weakPasswordError: "Please choose a stronger password",
    unknownError: "An unknown error occurred",
  },
  // "For a safer togetherness": "For a <1>safer</1> society",
  // "Fast and direct help with cross-border behavior": "We help where help is needed.",
  // "Hello!": "Hello!",
  // Back: "Back",
  // hello:
  //   "Welcome to the saferspaces administration tool. Enter your e-mail address if you are already registered or have received an invitation to the administration from us.",
  // invalidEmail: "Please use a valid email address",
  // verifyEmail: "Please verify your email address",
  // Continue: "Continue",
  // register: "Get in touch",
  // almostDone: "Almost done! Type in the code that we have sent to ",
  // ". You can also log in via the link in the email directly":
  //   ". You can also log in via the link in the email directly.",
  // codeError:
  //   "If you did not receive an email, ensure that you entered the address, you registered with.",
  // verifyCode: "Please verfiy the code and if you did already register.",
  // or: "or",
  // email: "E-Mail",
  // login: "Login",
  // loginNow: "Login",
  // registerEmailButton: "Register now",
  // getInTouch:
  //   "Thank you for your interest! To get started, book a short appointment with us. We'll show you how you can test saferspaces for free and get the most out of our application.",
  // getInTouch2: "At which location or event would you like to use saferspaces?",
  // venueOrEventName: "Name",
  // next: "Next",
  // back: "Back",
  // userAlreadyExists: "A user with this email address already exists.",
} as const;
