import { useTranslation } from "react-i18next";

import { Box } from "@/system/atoms/Box";

import CMYKColorPicker, { CMYKColor } from "./CMYKColorPicker";

const QRCodeColorPicker = (props: {
  disabled?: boolean;
  bgColor: CMYKColor | null;
  fgColor: CMYKColor;
  onChange: <
    T extends {
      bgColor: CMYKColor | null;
      fgColor: CMYKColor;
    },
  >(
    settings: T,
  ) => void;
}) => {
  const { t } = useTranslation("venue", { keyPrefix: "print" });

  return (
    <>
      <Box mb={2}>
        <CMYKColorPicker
          disabled={props.disabled}
          label={t("settings.background")}
          color={props.bgColor}
          onChange={(color) => {
            props.onChange({ fgColor: props.fgColor, bgColor: color });
          }}
        />
      </Box>

      <Box>
        <CMYKColorPicker
          disabled={props.disabled}
          label={t("settings.foreground")}
          color={props.fgColor}
          onChange={(color) => {
            if (!color) return;

            props.onChange({
              bgColor: props.bgColor,
              fgColor: color,
            });
          }}
        />
      </Box>
    </>
  );
};

export default QRCodeColorPicker;
