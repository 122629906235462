import { colorStringToRgba } from "@/utils/color";
import { styled } from "@/system/theme";

export const Input = styled("input")(({ theme }) => ({
  padding: "12px 8px",
  fontFamily: theme.typography.fontFamily,
  fontWeight: 700,
  textAlign: "center",
  border: `1px solid ${colorStringToRgba(theme.palette.primary.main, 0.2)}`,

  "&:disabled": {
    color: "rgba(0, 0, 0, 0.2)",
  },

  "&:focus": {
    outline: "none",
  },
}));

export default Input;
