import { ReactNode, forwardRef } from "react";

import { Box } from "./system/atoms/Box";
import { makeStyles } from "./system/theme";

import { CustomContentProps } from "notistack";

export interface CustomSnackbarProps extends CustomContentProps {
  children: ReactNode;
}

const styles = makeStyles((theme) => {
  return {
    wrapper: {
      boxShadow: theme.shadows[10],
      padding: theme.spacing(1, 2),
      borderRadius: theme.spacing(2),
      background: (props: {
        variant: "default" | "error" | "success" | "warning" | "info" | "download" | "create";
      }) => {
        return ["error", "success", "warning", "info"].includes(props.variant)
          ? theme.palette[props.variant as "error" | "success" | "warning" | "info"].main
          : "#ffffff";
      },
    },
  };
});

export const CustomSnackbar = forwardRef<HTMLDivElement, CustomContentProps>(
  (props, forwardedRef) => {
    const classes = styles({ variant: props.variant });

    return (
      <Box className={classes.wrapper} ref={forwardedRef}>
        {props.children}
      </Box>
    );
  },
);
