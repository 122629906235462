import React, { useContext, useEffect } from "react";

import { ApolloError, ApolloQueryResult } from "@apollo/client";

import { Exact, MeQuery, useMeLazyQuery } from "../../generated/graphql";

import { useRefresh } from "./hooks";
import SessionStorage from "@/utils/sessionStorage/SessionStorage";
import { ContextException } from "@/exceptions/ContextException";

interface IAuthContext {
  // state: QueryResult<MeQuery>;
  loading: boolean;
  error?: ApolloError;
  me?: MeQuery["me"];
  refetch?: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<MeQuery>>;
}

const AuthContext = React.createContext<IAuthContext | undefined>(undefined);

export const AuthProvider: React.FunctionComponent = ({ children }) => {
  const [query, { called, loading, error, data, refetch }] = useMeLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  useRefresh(refetch, data?.me);

  useEffect(() => {
    if (!called) {
      query();
    }
  }, [called, query]);

  useEffect(() => {
    if (error) {
      console.error({ error });
      SessionStorage.removeAccesToken();
    }
  }, [error]);

  if (!called || !data) return null;

  return (
    <AuthContext.Provider
      value={{
        loading,
        error,
        me: data?.me,
        refetch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new ContextException({ context: "AuthContext" });
  }

  return context;
};

export default useAuthContext;
