export const auth = {
  backgroundImageAlt: "Eine Wand mit mehreren Plakaten von saferspaces",
  backgroundImageTitle: "Für ein <1>respektvolles</1> Miteinander",
  backgroundImageDescription: "Da sein, wenn Hilfe gebraucht wird",
  login: {
    hello: "Hallo!",
    welcomeMessage:
      "Willkommen im Verwaltungstool von saferspaces. Logge dich jetzt ein, wenn du bereits einen Account hast.",
    or: "oder",
    forgotPassword: "Passwort vergessen?",
    noAccount: "Noch kein Konto?",
    bookADemo: "Jetzt Demo buchen",
    emailInputLabel: "E-Mail-Adresse",
    passwordInputLabel: "Passwort",
    stayLoggedIn: "Eingeloggt bleiben",
    userNotFound: "Unter dieser E-Mail-Adresse wurde kein*e Nutzer*in gefunden",
    wrongPassword: "Überprüfe bitte dein Passwort",
    unknownError: "Ein unbekannter Fehler ist aufgetreten",
    continue: "Weiter",
    button: "Einloggen",
    restoreAccount: "Probleme beim einloggen?",
  },
  restoreAccount: {
    title: "Hallo!",
    description:
      "Wenn du dich zuvor mit Einmalcode angemeldet hast, kann du hier eine neue Login Methode wählen. Bitte gib die E-Mail-Adresse ein, mit der du dich registriert hast. Wir senden dir eine E-Mail mit weiteren Anweisungen.",
  },
  register: {
    hello: "Hallo!",
    welcomeMessage:
      "Du hast noch keinen Account oder dich bisher mit Einmalcode angemeldet? Kein Problem, registriere dich jetzt neu.",
    or: "oder",
    alreadyRegistered: "Bereits registriert?",
    goToLogin: "Zum Login",
    emailInputLabel: "E-Mail-Adresse",
    passwordInputLabel: "Passwort",
    privacyAndTerms:
      "Mit der Registrierung stimmst du unseren <1>Datenschutzbestimmungen</1> und <3>AGB</3> zu.",
    button: "Jetzt registrieren",
    unknownError: "Ein unbekannter Fehler ist aufgetreten",
    invalidEmail: "Bitte gib eine gültige E-Mail-Adresse ein",
    emailAlreadyInUse: "Diese E-Mail-Adresse wird bereits verwendet",
    passwordError: "Bitte gib ein Passwort ein",
    weakPasswordError: "Das Passwort ist zu schwach",
  },
  resetPassword: {
    hello: "Hallo!",
    welcomeMessage:
      "Du hast dein Passwort vergessen oder dich bisher mit Einmalcode angemeldet? Kein Problem, wir schicken dir eine E-Mail um ein neues Passwort zu vergeben.",
    emailInputLabel: "E-Mail-Adresse",
    button: "Passwort zurücksetzen",
    backToLogin: "Zurück zum Login",
    userNotFound: "Unter dieser E-Mail-Adresse wurde kein*e Nutzer*in gefunden",
    unknownError: "Ein unbekannter Fehler ist aufgetreten",
    passwordNotSet: "Für diese E-Mail-Adresse wurde noch kein Passwort gesetzt",
    emailSentAt:
      "Wenn du ein Konto bei uns hast, wurde eine E-Mail an <strong>{{email}}</strong> gesendet. Bitte überprüfe deinen Posteingang und folge den Anweisungen, um dein Passwort zurückzusetzen.",
  },
  delete: {
    hello: "Hallo!",
    description:
      "Du kannst hier deine Daten löschen lassen. Bitte beachte, dass wir deine Daten nur löschen können, wenn du uns die E-Mail-Adresse angibst, mit der du dich registriert hast. Wir werden dich per E-Mail kontaktieren, um deine Identität zu bestätigen.",
    confirmation:
      "Deine Löschanfrage ist eingegangen. Wir kümmern uns umgehend und melden uns in spätestens 30 Tagen bei dir.",
    almost: "Fast geschafft! Als nächstes trage bitte den Code ein, den wir dir an ",
    done: " geschickt haben, um deine Identität zu bestätigen oder nutze den Link in der E-Mail.",
    rateLimit:
      "Du hast die maximale Anzahl an Löschanfragen erreicht. Bitte versuche es später erneut.",
    requestDeletion: "Löschanfrage stellen",
    verify: "Bitte überprüfe den Code und ob du bereits registiert bist.",
  },
  verifyEmail: {
    title: "Zu deiner Sicherheit",
    description:
      "Bitte bestätige deine E-Mail-Adresse, um fortzufahren. Hierfür haben wir dir eine E-Mail mit weiteren Anweisungen an <1>{{email}}</1> geschickt.",
    noEmail:
      "Solltest du keine E-Mail erhalten haben, überprüfe bitte deinen Spam-Ordner oder fordere eine neue E-Mail an.",
    resendEmail: "E-Mail erneut senden",
  },
  setPassword: {
    title: "Hallo!",
    description:
      "Möchtest du zusätzlich ein Passwort nutzen? Dieses benötigst du aktuell, um dich in der App anzumelden und eingehende Fälle zu betreuen. Wenn du bereits ein Passwort für die App hast, musst dies hier erneut setzen.",
    passwordInputLabel: "Passwort",
    submit: "Passwort setzen",
    skip: "Überspringen",
    weakPasswordError: "Bitte gib ein stärkeres Passwort ein",
    unknownError: "Ein unbekannter Fehler ist aufgetreten",
  },
  // "For a safer togetherness": "Für ein <1>respektvolles</1> Miteinander",
  // "Fast and direct help with cross-border behavior": "Helfen, wenn Hilfe gebraucht wird",
  // "Hello!": "Hallo!",
  // Back: "Zurück",
  // hello:
  //   "Willkommen im Verwaltungstool von saferspaces. Gebe deine E-Mail Adresse ein, wenn du bereits angemeldet bist oder eine Einladung in die Verwaltung von uns erhalten hast.",
  // invalidEmail: "Bitte gib eine gültige E-Mail-Adresse ein",
  // verifyEmail: "Mit der angegebenen E-Mail Adresse wurde bereits ein*e Nutzer*in gefunden.",
  // Continue: "Fortfahren",
  // register: "Termin buchen",
  // almostDone: "Fast geschafft! Als nächstes trage bitte den Code ein, den wir dir an ",
  // ". You can also log in via the link in the email directly":
  //   "geschickt haben. Du kannst dich auch direkt über den Link in der E-Mail anmelden.",
  // codeError:
  //   "Solltest du keine E-Mail erhalten haben, vergewissere dich, ob du schon angemeldet bist.",
  // verifyCode: "Bitte überprüfe den Code und ob du bereits registiert bist.",
  // or: "oder",
  // email: "E-Mail",
  // login: "Einloggen",
  // loginNow: "Einloggen",
  // registerEmailButton: "Jetzt registrieren",
  // getInTouch:
  //   "Vielen Dank schonmal für dein Interesse! Um loszulegen, buche einen kurzen Termin mit uns. Hier zeigen wir dir, wie du saferspaces kostenlos testen und unsere Anwendung optimal nutzen kannst.",
  // getInTouch2: "An welchem Ort oder bei welcher Veranstaltung möchtest du saferspaces einsetzen?",
  // venueOrEventName: "Ort oder Veranstaltung",
  // next: "Weiter",
  // back: "Zurück",
  // userAlreadyExists: "Mit der angegebenen E-Mail Adresse wurde bereits ein*e Nutzer*in gefunden.",
} as const;
