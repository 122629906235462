import { BACKEND_BASE_URL } from "@/config";
import SessionStorage from "@/utils/sessionStorage/SessionStorage";
import { useState } from "react";

export const storageClient = async (path: string) => {
  const result = await wrappedFetch(`${BACKEND_BASE_URL}/storage/${path}`);

  return result;
};

export const wrappedFetch = async (url: string) => {
  const accessToken = SessionStorage.getAccesToken;

  try {
    let result;

    if (accessToken) {
      result = await fetch(url, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    } else {
      result = await fetch(url);
    }

    if (result.status === 200) {
      return result;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const useProgressReader = () => {
  const [progress, setProgress] = useState(0);

  const readProgress = async (response: Response) => {
    // Step 1: get reader
    const reader = response.body?.getReader();

    if (!reader) {
      return new Blob();
    }

    // Step 2: get total length
    const contentLength = parseInt(response.headers.get("Content-Length") ?? "1", 10);

    // Step 3: read the data
    let receivedLength = 0; // received that many bytes at the moment
    const chunks: Uint8Array[] = []; // array of received binary chunks (comprises the body)
    while (true) {
      const { done, value } = await reader.read();

      if (done) {
        break;
      }

      chunks.push(value);
      receivedLength += value.length;

      const percent = Math.ceil((receivedLength / contentLength) * 100);
      setProgress(percent);
    }

    return new Blob(chunks);
  };

  return { progress, readProgress };
};
