import { forwardRef, useEffect } from "react";

import { CustomContentProps, useSnackbar } from "notistack";

import { storageClient, useProgressReader } from "./api";
import { Box } from "./system/atoms/Box";
import { makeStyles } from "./system/theme";
import { CopySmallHero } from "./system/atoms/Typography";

export interface DownloadSnackbarProps extends CustomContentProps {
  path: string;
}

const createDownloadLink = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode?.removeChild(link);
};

const styles = makeStyles((theme) => {
  return {
    wrapper: {
      boxShadow: theme.shadows[10],
      padding: theme.spacing(1, 2),
      borderRadius: theme.spacing(2),
      transition: "all 0.5s",
      color: ({ progress }: { progress: number }) => {
        return progress === 100 ? "#ffffff" : theme.palette.text.primary;
      },
      backgroundImage: ({ progress }: { progress: number }) => {
        return `
          linear-gradient(
            to right,
            #ffffff 0%, 
            ${progress === 100 ? `${theme.palette.secondary.main} 0%` : "#ffffff 100%"}, 
            ${theme.palette.secondary.main} 100%
          )
        `;
      },
    },
  };
});

export const DownloadSnackbar = forwardRef<HTMLDivElement, DownloadSnackbarProps>(
  (props, forwardedRef) => {
    const { closeSnackbar } = useSnackbar();
    const { progress, readProgress } = useProgressReader();
    const classes = styles({ progress });

    useEffect(() => {
      const execute = async () => {
        if (props.path) {
          const response = await storageClient(props.path);

          if (response) {
            const blob = await readProgress(response);

            const fileName = props.path.split("/").pop() ?? "download";

            createDownloadLink(blob, fileName);
          }
        }
      };

      execute();
    }, []);

    useEffect(() => {
      let timeout: NodeJS.Timeout;

      if (progress === 100) {
        timeout = setTimeout(() => {
          closeSnackbar(props.id);
        }, 3000);
      }

      return () => {
        clearTimeout(timeout);
      };
    }, [progress, closeSnackbar, props.id]);

    return (
      <Box className={classes.wrapper} ref={forwardedRef}>
        <CopySmallHero color="inherit">
          {progress}% {props.message}
        </CopySmallHero>
      </Box>
    );
  },
);
