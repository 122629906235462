/**
 * Color
 */
export function cmykToHex({
  cyan,
  magenta,
  yellow,
  key,
}: {
  cyan: number;
  magenta: number;
  yellow: number;
  key: number;
}): string {
  cyan = cyan / 100;
  magenta = magenta / 100;
  yellow = yellow / 100;
  key = key / 100;

  const r = Math.round(255 * (1 - cyan) * (1 - key));
  const g = Math.round(255 * (1 - magenta) * (1 - key));
  const b = Math.round(255 * (1 - yellow) * (1 - key));

  const componentToHex = (component: number) => {
    const hex = component.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const hexColor = "#" + componentToHex(r) + componentToHex(g) + componentToHex(b) + "FF";
  return hexColor.toUpperCase();
}

/**
 *
 */
export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export const clampPercent = (num: number) => Math.min(Math.max(num, 0), 100);
