import * as Sentry from "@sentry/react";

import { ENVIRONMENT, NAME, SENTRY_DSN, VERSION } from "@/config";

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  release: `${NAME}@${VERSION}`,
  integrations: [Sentry.captureConsoleIntegration({ levels: ["error"] })],
  autoSessionTracking: false,
  sendDefaultPii: false,
  beforeSend: async (event) => {
    if (ENVIRONMENT === "staging" || ENVIRONMENT === "production") {
      return event;
    } else {
      return null;
    }
  },
});
