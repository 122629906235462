import { useEffect } from "react";

import { Exact, MeQuery, useAppRefreshMutation } from "@/generated/graphql";
import { ApolloQueryResult } from "@apollo/client";
import LocalStorage from "@/utils/localStorage/LocalStorage";
import SessionStorage from "@/utils/sessionStorage/SessionStorage";

export const useRefresh = (
  refetch:
    | ((
        variables?:
          | Partial<
              Exact<{
                [key: string]: never;
              }>
            >
          | undefined,
      ) => Promise<ApolloQueryResult<MeQuery>>)
    | undefined,
  me?: MeQuery["me"],
) => {
  const [refresh, result] = useAppRefreshMutation();

  useEffect(() => {
    if (result.data?.appRefresh.__typename === "AppRefreshReturn") {
      SessionStorage.setAccesToken(result.data.appRefresh.accessToken);
      LocalStorage.setSessionId(result.data.appRefresh.sessionId);
    } else {
      if (refetch) {
        refetch();
      }
    }
  }, [result.data]);

  useEffect(() => {
    const sessionId = LocalStorage.getSessionId;
    let interval: NodeJS.Timeout;

    if (me?.__typename === "User") {
      if (sessionId) {
        refresh({ variables: { sessionId } });
      }

      interval = setInterval(() => {
        const sessionId = LocalStorage.getSessionId;

        if (sessionId) {
          refresh({ variables: { sessionId } });
        }
      }, 1000 * 60 * 5);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [me, refresh]);

  return result;
};
