// eslint-disable-next-line
export const omitDeepArrayWalk = <T>(arr: any[], key: keyof T): any => {
  return arr.map((val) => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key);
    else if (typeof val === "object") return omitDeep(val, key);
    return val;
  });
};

export const omitDeep = <T extends { [key: string | number | symbol]: string | number | Date }>(
  obj: T,
  key: keyof T,
) => {
  const keys = Object.keys(obj) as (keyof T)[];
  const newObj = {} as T;
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i];
      if (val instanceof Date) newObj[i] = val;
      else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key);
      else if (typeof val === "object" && val !== null)
        // eslint-disable-next-line
        newObj[i] = omitDeep(val as any, key) as any;
      else newObj[i] = val;
    }
  });
  return newObj;
};
