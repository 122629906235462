import { forwardRef } from "react";

import { Box } from "./system/atoms/Box";
import { makeStyles } from "./system/theme";
import { CopySmallHero } from "./system/atoms/Typography";
import { CustomContentProps } from "notistack";

const styles = makeStyles((theme) => {
  return {
    wrapper: {
      boxShadow: theme.shadows[10],
      padding: theme.spacing(1, 2),
      borderRadius: theme.spacing(2),
      background: theme.palette.error.main,
      color: "#ffffff",
    },
  };
});

export const ErrorSnackbar = forwardRef<HTMLDivElement, CustomContentProps>(
  (props, forwardedRef) => {
    const classes = styles();

    return (
      <Box className={classes.wrapper} ref={forwardedRef}>
        <CopySmallHero color="inherit">{props.message}</CopySmallHero>
      </Box>
    );
  },
);
