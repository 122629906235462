import { useEffect, useRef } from "react";

import qrcode from "qrcode";

import GpsFixed from "@mui/icons-material/GpsFixed";
import Box from "@mui/material/Box";

import {
  QrCodeDynamicFragmentFragment,
  QrCodeHelpFragmentFragment,
  QrCodeInternalChatFragmentFragment,
} from "@/generated/graphql";
import { useTheme } from "@/system/theme";
import { Logo, NFC } from "@/system/atoms/Assets/Assets";
import { getQRCodeUrl } from "@/utils/entities/qrCode/qrCode";
import { applyOpacityToRgb, getHexFromColor } from "@/utils/color";

function useGetQRCodeColor(typename: string) {
  const theme = useTheme();

  const bgColor = "white";

  switch (typename) {
    case "QRCodeDynamic":
      return { bgColor, fgColor: theme.palette.text.primary };
    case "QRCodeHelp":
      return { bgColor, fgColor: theme.palette.primary.main };
    case "QRCodeInternalChat":
      return { bgColor, fgColor: theme.palette.secondary.main };
    default:
      return { bgColor, fgColor: theme.palette.primary.main };
  }
}

const NFCTagBox = (props: { scale: number; fgColor: string }) => {
  const size = props.scale * 16;

  return (
    <div
      style={{
        // Display
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // Position
        position: "absolute",
        top: 0,
        left: 0,
        // Size
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          // Decoration
          boxShadow: `2px 2px 8px 0px ${applyOpacityToRgb(props.fgColor, 0.25)}`,
          background: "white",
          borderRadius: size / 2,
          // Size
          height: size,
          width: size,
        }}
      >
        <NFC stroke={props.fgColor} fill={props.fgColor} height={size} width={size} />
      </div>
    </div>
  );
};

export const MissingPositionBox = () => {
  return (
    <div
      style={{
        // Decoration
        background: "rgba(255, 255, 255, 0.9)",
        // Display
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // Position
        position: "absolute",
        top: 0,
        left: 0,
        // Size
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        boxShadow="1"
        p={0.5}
        style={{
          borderRadius: "16px",
          background: "white",
          color: "#f13958",
        }}
      >
        <GpsFixed color="inherit" />
      </Box>
    </div>
  );
};

const LogoBox = (props: { scale: number; fgColor: string; bgColor: string }) => {
  return (
    <div
      style={{
        // Position
        position: "absolute",
        bottom: 0,
        right: 0,
        // Size
        paddingTop: props.scale,
        paddingLeft: props.scale,
        lineHeight: "1px",
      }}
    >
      <Logo
        fill={props.fgColor}
        height={props.scale * 10}
        width={props.scale * 10}
        style={{
          transform: "rotate(-90deg)",
          position: "absolute",
          bottom: 0,
          right: 0,
          zIndex: 1,
        }}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={props.scale * 12}
        width={props.scale * 12}
        viewBox="0 0 95 95"
        style={{ position: "absolute", bottom: 0, right: 0, zIndex: 0 }}
      >
        <rect fill={props.bgColor} x="0" y={95 - 32} width="95" height="33" />
        <rect fill={props.bgColor} x={95 - 32} y="0" width="33" height="95" />
      </svg>
    </div>
  );
};

export const QRCodeWithDecoration = (props: {
  // venueId: A string representing the unique identifier of the venue.
  venueId: string;

  // qrCodeId: A string representing the unique identifier of the QR code.
  qrCodeId: string;

  /**
   * typename: A string representing the type of the QR code. This can be one of the following:
   * - QrCodeHelpFragmentFragment: Represents a QR code for visitors.
   * - QrCodeInternalChatFragmentFragment: Represents a QR code for staff.
   * - QrCodeDynamicFragmentFragment: Represents a dynamic QR code.
   */
  typename: (
    | QrCodeHelpFragmentFragment
    | QrCodeInternalChatFragmentFragment
    | QrCodeDynamicFragmentFragment
  )["__typename"];

  // Scale of the QR code
  scale: number;
  // Flags for decoration
  hasMissingPosition?: boolean;
  requirePosition?: boolean;
  hasNfcTag?: boolean;

  onClick?: (url: string) => void;
}) => {
  const url = getQRCodeUrl({ qrCodeId: props.qrCodeId }).href;
  const { bgColor, fgColor } = useGetQRCodeColor(props.typename);
  const showPositionError = props.hasMissingPosition && props.requirePosition;

  return (
    <div
      style={{
        // Position
        position: "relative",
        // Display
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "fit-content",
      }}
      onClick={() => {
        props.onClick?.(url);
      }}
    >
      {props.hasNfcTag ? <NFCTagBox scale={props.scale} fgColor={fgColor} /> : null}

      {showPositionError ? <MissingPositionBox /> : null}

      <QRCodeCanvas scale={props.scale} bgColor={bgColor} fgColor={fgColor} url={url} />

      <LogoBox scale={props.scale} fgColor={fgColor} bgColor={bgColor} />
    </div>
  );
};

export const QRCodeCanvas = (props: {
  url: string;
  scale: number;
  fgColor: string;
  bgColor: string;
}) => {
  const ref = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const render = async () => {
      try {
        await qrcode.toCanvas(ref.current, props.url, {
          margin: 0,
          scale: props.scale,
          version: 7,
          color: {
            dark: getHexFromColor(props.fgColor),
            light: getHexFromColor(props.bgColor),
          },
        });
      } catch (error) {
        console.error(error);

        // Probably paint the canvas red
      }
    };

    render();
  }, [props.scale, props.fgColor, props.bgColor, props.url]);

  return <canvas ref={ref} />;
};
