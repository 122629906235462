import { cmykToHex } from "@/features/print/utils";

const rgbRegExp = new RegExp(/\((.*)\)/);
const hexRegExp = new RegExp(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);

export function colorStringToRgba(
  color: string,
  opacity: number,
): `rgba(${string},${string},${string},${string})` {
  const hexMatch = color.match(hexRegExp);

  if (hexMatch) {
    const [_group, r, g, b] = hexMatch;

    return `rgba(${r},${g},${b},${opacity})`;
  }

  const rgbMatch = color.match(new RegExp(/\((.*)\)/));

  if (rgbMatch) {
    const [_group, rgb] = rgbMatch;

    const [r, g, b] = rgb.split(",").map(Number);

    return `rgba(${r},${g},${b},${opacity})`;
  }

  return `rgba(0,0,0,${opacity})`;
}

function componentToNumber(c: string) {
  return parseInt(c, 16);
}

export function colorStringToRgbComponents(color: string): { r: number; g: number; b: number } {
  const hexMatch = color.match(new RegExp(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i));

  if (hexMatch) {
    const [_group, r, g, b] = hexMatch;

    return { r: componentToNumber(r), g: componentToNumber(g), b: componentToNumber(b) };
  }

  const rgbMatch = color.match(new RegExp(/\((.*)\)/));

  if (rgbMatch) {
    const [_group, rgb] = rgbMatch;

    const [r, g, b] = rgb.split(",").map(Number);

    return { r, g, b };
  }

  return { r: 0, g: 0, b: 0 };
}

/**
 * Converts a decimal number to a hexadecimal string. If the hexadecimal string is of length 1, it
 * prepends a "0" to it.
 *
 * @example
 * const hex = componentToHex(0); // "00"
 * const hex = componentToHex(255); // "ff"
 *
 * @param {number} c - The decimal number to convert to hexadecimal.
 * @return {string} The hexadecimal representation of the input number.
 */
function componentToHex(c: number): string {
  // Take care of unintended edge cases where the number is less than 0 or greater than 255. In
  // these cases, return "00" or "ff" respectively, as the hexadecimal representation.
  if (c < 0) {
    return "00";
  } else if (c > 255) {
    return "ff";
  }

  const hex = c.toString(16);

  return hex.length === 1 ? "0" + hex : hex;
}

/**
 * Converts a color from RGB or named format to hexadecimal format.
 *
 * @param {string} color - The color to convert. This can be in RGB format (e.g., "rgb(255,255,255)"),
 *                         in hexadecimal format (e.g., "#ffffff"), or a named color ("white").
 * @return {string} The color in hexadecimal format. If the input is already in hexadecimal format,
 *                  it is returned as is. If the input is a named color, it is converted to its
 *                  equivalent hexadecimal color. Currently, only "white" and "black" are supported
 *                  for named colors, and any other named color will default to "black".
 */
export function getHexFromColor(color: string): string {
  const rgbMatch = color.match(rgbRegExp);

  if (rgbMatch) {
    const [_group, rgb] = rgbMatch;

    const rgbArray = rgb.split(",").map(Number);
    const hexArray = rgbArray.map(componentToHex);
    const hexColors = hexArray.join("");

    return `#${hexColors}`;
  } else if (color[0] === "#") {
    return color;
  } else if (color.split(",").length === 4) {
    const [cyan, magenta, yellow, key] = color.split(",").map(Number);

    return cmykToHex({ cyan, magenta, yellow, key });
  } else {
    switch (color) {
      case "white":
        return "#fff";
      case "black":
        return "#000";
      default:
        return "#000";
    }
  }
}

/**
 * Applies opacity to an RGB color.
 *
 * @param {string} color - The color to which opacity should be applied. This should be in RGB format (e.g., "rgb(255,255,255)").
 * @param {number} opacity - The opacity to apply. This should be a number between 0 (completely transparent) and 1 (completely opaque).
 * @return {string} The color with the applied opacity, in RGBA format (e.g., "rgba(255,255,255,0.5)").
 *                  If the input color is not in RGB format, the function will return the input color as is.
 */
export function applyOpacityToRgb(color: string, opacity: number): string | undefined {
  const match = color.match(new RegExp(/\((.*)\)/));

  if (match) {
    const [_group, rgb] = match;

    return `rgba(${rgb}, ${opacity})`;
  }
}
