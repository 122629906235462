import { Box } from "@/system/atoms/Box";
import TextField from "@/system/atoms/TextField/TextField";
import { useState } from "react";
import QRCodeColorPicker from "@/features/print/QRCodeColorPicker";
import { QRCodeCanvas } from "@/system/atoms/QRCode";

export const QRCodeEditor = () => {
  const [state, setState] = useState({
    url: "www.saferspaces.io",
    scale: 7,
    foreground: {
      cyan: 0,
      magenta: 0,
      yellow: 0,
      key: 100,
    },
    background: {
      cyan: 0,
      magenta: 0,
      yellow: 0,
      key: 0,
    },
  });

  return (
    <Box width={1} height={1} display="flex" justifyContent="center" alignItems="center">
      <Box minWidth={480} bgcolor="white" p={4} borderRadius={1}>
        <TextField
          value={state.url}
          onChange={(event) => {
            setState((state) => ({
              ...state,
              url: event.target.value,
            }));
          }}
          fullWidth
        />

        <QRCodeColorPicker
          bgColor={state.background}
          fgColor={state.foreground}
          onChange={(color) => {
            const { bgColor, fgColor } = color;

            if (bgColor && fgColor) {
              setState((state) => ({
                ...state,
                foreground: fgColor,
                background: bgColor,
              }));
            }
          }}
        />

        <Box my={4} />

        <Box textAlign="center">
          <QRCodeCanvas
            url={state.url}
            scale={state.scale}
            fgColor={
              state.foreground.cyan +
              "," +
              state.foreground.magenta +
              "," +
              state.foreground.yellow +
              "," +
              state.foreground.key
            }
            bgColor={
              state.background.cyan +
              "," +
              state.background.magenta +
              "," +
              state.background.yellow +
              "," +
              state.background.key
            }
          />
        </Box>
      </Box>
    </Box>
  );
};
