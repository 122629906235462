import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import { ContextException } from "./exceptions/ContextException";
import { SnackbarProvider as NotistackSnackbarProvider } from "notistack";
import { DownloadSnackbar } from "./snackbar-download";
import { CreateSnackbar } from "./snackbar-create";

import { CreatePrintJobInput } from "./generated/graphql";
import { ErrorSnackbar } from "./snackbar-error";
import { CustomSnackbar } from "./snackbar-custom";

declare module "notistack" {
  interface VariantOverrides {
    download: {
      path: string;
    };
    create: {
      input: CreatePrintJobInput;
      updateQuery: any;
    };
  }
}

export const SnackbarContext = createContext<{
  setSnackbar: Dispatch<
    SetStateAction<null | { severity: "info" | "success" | "error"; message: string }>
  >;
}>({
  setSnackbar: () => {
    //
  },
});

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [snackbar, setSnackbar] = useState<null | {
    severity: "info" | "success" | "error";
    message: string;
  }>(null);

  return (
    <NotistackSnackbarProvider
      maxSnack={3}
      Components={{
        default: CustomSnackbar,
        info: CustomSnackbar,
        success: CustomSnackbar,
        warning: CustomSnackbar,
        error: ErrorSnackbar,
        create: CreateSnackbar,
        download: DownloadSnackbar,
      }}
    >
      <SnackbarContext.Provider value={{ setSnackbar }}>
        {snackbar ? (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(snackbar.message)}
            autoHideDuration={6000}
            onClose={() => {
              setSnackbar(null);
            }}
          >
            <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
          </Snackbar>
        ) : null}

        {children}
      </SnackbarContext.Provider>
    </NotistackSnackbarProvider>
  );
};

export const useSnackbarContext = () => {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new ContextException({ context: "SnackbarContext" });
  }

  return context;
};
