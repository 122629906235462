import {
  ThemeProvider as MuiThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import StylesProvider from "@mui/styles/StylesProvider";
import { ThemeProvider } from "styled-components";

import { ApolloProvider } from "@apollo/client";

import { AuthProvider } from "./providers/Auth/AuthProvider";
import client from "./client";
import { theme } from "./system/theme";
import GlobalStyle from "./GlobalStyles";
import componentTheme from "./system/theme.components";
import Root from "./pages/routes";
import { SnackbarProvider } from "./snackbar";

import "./AppConfig";
import "./App.css";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <MuiThemeProvider theme={componentTheme}>
          <ThemeProvider theme={theme}>
            <StylesProvider injectFirst>
              <ApolloProvider client={client}>
                <SnackbarProvider>
                  <AuthProvider>
                    <GlobalStyle />

                    <BrowserRouter>
                      <Root />
                    </BrowserRouter>
                  </AuthProvider>
                </SnackbarProvider>
              </ApolloProvider>
            </StylesProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
