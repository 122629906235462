import { venue as venueDE } from "./de/venue";
import { venue as venueEN } from "./en/venue";

import { auth as authDE } from "./de/auth";
import { auth as authEN } from "./en/auth";

import { backend as backendDE } from "./de/backend";
import { backend as backendEN } from "./en/backend";

import { dashboard as dashboardDE } from "./de/dashboard";
import { dashboard as dashboardEN } from "./en/dashboard";

import { features as featuresDE } from "./de/features";
import { features as featuresEN } from "./en/features";

/**
 * Translation
 */
export const supportedLngs = ["de", "en"] as ["de", "en"];
export type ISupportedLngs = (typeof supportedLngs)[number];
export const fallbackLng: ISupportedLngs = "de";

export const resources = {
  en: {
    auth: authEN,
    dashboard: dashboardEN,
    venue: venueEN,
    features: featuresEN,
    backend: backendEN,
  },
  de: {
    auth: authDE,
    dashboard: dashboardDE,
    venue: venueDE,
    features: featuresDE,
    backend: backendDE,
  },
} as const;

export const ns = ["auth", "dashboard", "venue", "features", "backend"] as const;
export type INs = (typeof ns)[number];
export const defaultNS: INs = "dashboard";
