import { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CustomContentProps, useSnackbar } from "notistack";

import { Box } from "./system/atoms/Box";
import { makeStyles } from "./system/theme";
import { CopySmallHero } from "./system/atoms/Typography";
import {
  CreatePrintJobInput,
  PrintJobStatus,
  PrintJobsQueryVariables,
  useCreatePrintJobMutation,
} from "./generated/graphql";
import { CircularProgress } from "@mui/material";
import { DocumentNode } from "graphql";

export interface CreateSnackbarProps extends CustomContentProps {
  input: CreatePrintJobInput;
  updateQuery: {
    query: DocumentNode;
    variables: PrintJobsQueryVariables;
  };
}

const styles = makeStyles((theme) => {
  return {
    wrapper: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
      boxShadow: theme.shadows[10],
      padding: theme.spacing(1, 2),
      borderRadius: theme.spacing(2),
      transition: "all 0.5s",
      color: ({ hasError, isFinished }: { hasError: boolean; isFinished: boolean }) => {
        if (hasError || isFinished) {
          return "#ffffff";
        } else {
          return theme.palette.text.primary;
        }
      },
      background: ({ hasError, isFinished }: { hasError: boolean; isFinished: boolean }) => {
        if (hasError) {
          return theme.palette.error.main;
        } else if (isFinished) {
          return theme.palette.secondary.main;
        } else {
          return "#ffffff";
        }
      },
    },
  };
});

export const CreateSnackbar = forwardRef<HTMLDivElement, CreateSnackbarProps>(
  (props, forwardedRef) => {
    const { t } = useTranslation("venue");
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const [createPrintJob, createPrintJobResult] = useCreatePrintJobMutation({
      variables: {
        input: props.input,
      },
      optimisticResponse(variables) {
        return {
          __typename: "Mutation",
          createPrintJob: {
            __typename: "PrintJob",
            id: "optimistic",
            status: PrintJobStatus.Pending,
            format: variables.input.format,
            template: variables.input.template,
            numberOfQRCodes: variables.input.count,
            timestamp: {
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
            },
            path: null,
            size: null,
          },
        };
      },
      update(cache, { data }) {
        cache.updateQuery(props.updateQuery, (current) => {
          return {
            printJobs: {
              items: [data?.createPrintJob, ...current.printJobs.items].slice(
                0,
                props.updateQuery.variables.pagination.take,
              ),
            },
          };
        });
      },
      refetchQueries: ["PrintJobs"],
    });

    const isFinished = createPrintJobResult.data !== undefined;
    const hasError = createPrintJobResult.data?.createPrintJob.status === PrintJobStatus.Error;

    const classes = styles({
      hasError,
      isFinished,
    });

    useEffect(() => {
      createPrintJob();
    }, []);

    useEffect(() => {
      let timeout: NodeJS.Timeout;
      const path = createPrintJobResult.data?.createPrintJob.path;

      if (path) {
        timeout = setTimeout(() => {
          closeSnackbar(props.id);
        }, 3000);

        enqueueSnackbar(t("print.position.downloadProgress"), {
          variant: "download",
          persist: true,
          path,
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
      }

      return () => {
        clearTimeout(timeout);
      };
    }, [isFinished]);

    return (
      <Box className={classes.wrapper} ref={forwardedRef}>
        {!isFinished ? <CircularProgress size={16} /> : null}

        <CopySmallHero color="inherit">
          {hasError ? t("print.position.createError") : props.message}
        </CopySmallHero>
      </Box>
    );
  },
);
