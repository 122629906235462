import { PropsWithChildren } from "react";

import MuiBox, { BoxProps as MuiBoxProps } from "@mui/material/Box";

type BoxProps = {
  cursor?: "pointer";
  onMouseOver?: () => void;
  onClick?: () => void;
  m?: number;
  mx?: number;
  my?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  p?: number;
  px?: number;
  py?: number;
  pt?: number;
  pr?: number;
  pb?: number;
  pl?: number;
  height?: number;
  width?: number;
  borderRadius?: number;
  flex?: number;
  color?: string;
  textAlign?: "left" | "center" | "right";
  background?: string;
  className?: string;
  position?: "relative" | "absolute";
  elevation?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19;
  flexShrink?: MuiBoxProps["flexShrink"];
};

export const Box = ({
  background,
  elevation,
  cursor,
  ...props
}: PropsWithChildren<BoxProps & { component: "header" | "main" | "footer" | "div" }>) => {
  return <MuiBox {...props} bgcolor={background} sx={{ boxShadow: elevation, cursor }} />;
};

export const FlexBox = ({
  background,
  elevation,
  ...props
}: PropsWithChildren<
  BoxProps & {
    flexWrap?: MuiBoxProps["flexWrap"];
    flexDirection?: MuiBoxProps["flexDirection"];
    justifyContent?: "center" | "space-between" | "flex-end";
    alignItems?: "center" | "flex-start" | "flex-end";
    component: "header" | "main" | "footer" | "div";
  }
>) => {
  return <MuiBox {...props} display="flex" bgcolor={background} sx={{ boxShadow: elevation }} />;
};

export const Header = (props: PropsWithChildren<BoxProps>) => {
  return <Box component="header" {...props} />;
};

export const Main = (props: PropsWithChildren<BoxProps>) => {
  return <Box component="main" {...props} />;
};

export const Footer = (props: PropsWithChildren<BoxProps>) => {
  return <Box component="footer" {...props} />;
};

export const Div = (props: PropsWithChildren<BoxProps>) => {
  return <Box component="div" {...props} />;
};
