export default class LocalStorage {
  private static _getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  private static _setItem(key: string, value: string) {
    return localStorage.setItem(key, value);
  }

  private static _removeItem(key: string): void {
    return localStorage.removeItem(key);
  }

  static setLanguage(language: string): void {
    this._setItem("language", language);
  }

  /**
   *
   */
  static get getSessionId(): string | null {
    return LocalStorage._getItem("session-id");
  }

  static setSessionId(sessionId: string): void {
    LocalStorage._setItem("session-id", sessionId);
  }

  /**
   * Whether the user wants to access beta features
   * @param overwrite
   */
  static setPreviewFeatures({ key, value }: { key: string; value: boolean }): void {
    LocalStorage._setItem(`previewFeatures:${key}`, value ? "true" : "false");
  }

  static getPreviewFeatures({ key }: { key: string }): boolean {
    const value = LocalStorage._getItem(`previewFeatures:${key}`);

    return value === "true" ? true : false;
  }

  /**
   *
   */

  static get lastVisitedProjets(): { name: string; id: string }[] | null {
    const value = LocalStorage._getItem("lastVisitedProjets");

    if (value) {
      return JSON.parse(value);
    } else {
      return null;
    }
  }

  static set setLastVisitedProjets(lastVisitedProjets: { name: string; id: string }[]) {
    LocalStorage._setItem("lastVisitedProjets", JSON.stringify(lastVisitedProjets));
  }

  static removeLastVisitedProjets() {
    LocalStorage._removeItem("lastVisitedProjets");
  }

  /**
   *
   */
  static removeSessionId(): void {
    LocalStorage._removeItem("session-id");
  }

  static get getLastLoginAddress(): string | null {
    return LocalStorage._getItem("lastLoginAddress");
  }

  static setLastLoginAddress(lastLoginAddress: string): void {
    LocalStorage._setItem("lastLoginAddress", lastLoginAddress);
  }

  static removeLastLoginAddress(): void {
    LocalStorage._removeItem("lastLoginAddress");
  }

  static get getUuid(): string | null {
    return LocalStorage._getItem("uuid");
  }
}
