export default class SessionStorage {
  private static _getItem(key: string) {
    return sessionStorage.getItem(key);
  }

  private static _setItem(key: string, value: string) {
    return sessionStorage.setItem(key, value);
  }

  private static _removeItem(key: string): void {
    return sessionStorage.removeItem(key);
  }

  static get getAccesToken(): string | null {
    return SessionStorage._getItem("access-token");
  }

  static setAccesToken(accesToken: string): void {
    SessionStorage._setItem("access-token", accesToken);
  }

  static removeAccesToken(): void {
    SessionStorage._removeItem("access-token");
  }

  static get getUuid(): string | null {
    return SessionStorage._getItem("uuid");
  }

  static setUuid(uuid: string): void {
    SessionStorage._setItem("uuid", uuid);
  }

  static get getLastLoginAddress(): string | null {
    return SessionStorage._getItem("lastLoginAddress");
  }

  static setLastLoginAddress(lastLoginAddress: string): void {
    SessionStorage._setItem("lastLoginAddress", lastLoginAddress);
  }

  /**
   * Whether the user wants to access beta features
   * @param overwrite
   */
  static setWantsBetaFeatures(overwrite: boolean): void {
    SessionStorage._setItem("wantsBetaFeatures", overwrite ? "true" : "false");
  }

  static get wantsBetaFeatures(): boolean {
    const hasAdminOverwrite = SessionStorage._getItem("wantsBetaFeatures");

    return hasAdminOverwrite === "true" ? true : false;
  }

  /**
   * Whether the user has admin overwrite
   * @param overwrite
   */
  static setHasAdminOverwrite(overwrite: boolean): void {
    SessionStorage._setItem("hasAdminOverwrite", overwrite ? "true" : "false");
  }

  static get hasAdminOverwrite(): boolean {
    const hasAdminOverwrite = SessionStorage._getItem("hasAdminOverwrite");

    return hasAdminOverwrite === "true" ? true : false;
  }

  /**
   *
   * @param open
   */
  static setSidebarOpen(open: boolean): void {
    SessionStorage._setItem("sidebarOpen", open ? "true" : "false");
  }

  static get getSidebarOpen(): boolean {
    const sidebarOpen = SessionStorage._getItem("sidebarOpen");

    return sidebarOpen === "false" ? false : true;
  }
}
