import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";

import * as Sentry from "@sentry/react";
import { setContext } from "@apollo/client/link/context";
import { automaticallyPersistedQueryLink } from "./client.automaticallyPersistedQueryLink";

import axios, { AxiosError, HttpStatusCode } from "axios";

import { BACKEND_BASE_URL, ENVIRONMENT, FRONTEND_OWNER_URL, NAME, VERSION } from "./config";
import { omitDeep } from "./utils/apollo";

import LocalStorage from "./utils/localStorage/LocalStorage";
import SessionStorage from "./utils/sessionStorage/SessionStorage";

class DateTime extends Date {
  static fromSeconds(unix: number) {
    return new this(unix * 1000);
  }

  difference(date: Date) {
    const milliseconds = this.valueOf() - date.valueOf();

    return {
      inMilliseconds: milliseconds,
      inSeconds: milliseconds / 1000,
    };
  }

  isBefore(date: Date) {
    return this.valueOf() - date.valueOf() < 0;
  }

  isSameOrBefore(date: Date) {
    return this.valueOf() - date.valueOf() <= 0;
  }
}

const refreshToken = async (sessionId: string) => {
  try {
    const result = await axios.post(`${BACKEND_BASE_URL}/refresh`, { sessionId });

    if (result.status === 200 && result.data.accessToken) {
      return { accessToken: result.data.accessToken };
    } else {
      return { accessToken: null, error: "Ein unbekannter Fehler ist aufgetreten." };
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response?.status === HttpStatusCode.NotFound) {
        LocalStorage.removeSessionId();
      }
    }

    return { accessToken: null, error };
  }
};

// TODO: Add tests
const authLink = setContext(async (request, defaultContext) => {
  const headers = defaultContext.headers;

  let accessToken = SessionStorage.getAccesToken;
  const sessionId = LocalStorage.getSessionId;

  if (!accessToken) {
    if (sessionId) {
      ({ accessToken } = await refreshToken(sessionId));
    }

    if (!accessToken) {
      return headers;
    }
  }

  let payload;

  try {
    payload = JSON.parse(atob(accessToken.split(".")[1]));
  } catch (error) {
    return headers;
  }

  let expiryDate;

  try {
    expiryDate = DateTime.fromSeconds(payload.exp);
  } catch (error) {
    return headers;
  }

  try {
    if (expiryDate.difference(new Date()).inSeconds < 60) {
      if (sessionId) {
        ({ accessToken } = await refreshToken(sessionId));

        if (!accessToken) {
          return headers;
        }
      } else {
        return headers;
      }
    }
  } catch (error) {
    if (!accessToken) {
      return headers;
    } else {
    }
  }

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...headers,
      "session-id": sessionId,
    },
  };
});

/**
 * Middlewares
 */
const sentryMiddleware = new ApolloLink((operation, forward) => {
  Sentry.addBreadcrumb({
    category: "query",
    message: operation.operationName,
    level: "info",
  });

  Sentry.setContext("query", {
    operationName: operation.operationName,
    variables: JSON.stringify(operation.variables),
  });

  return forward(operation);
});

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables && !operation.variables.file) {
    operation.variables = omitDeep(operation.variables, "__typename");
  }

  return forward(operation);
});

const httpLink = new HttpLink({
  uri: `${BACKEND_BASE_URL}/graphql`,
  credentials: ENVIRONMENT === "development" ? undefined : "include",
  headers: {
    "Access-Control-Allow-Origin": FRONTEND_OWNER_URL,
  },
});

const linkChain = automaticallyPersistedQueryLink.concat(httpLink);

const client = new ApolloClient({
  name: NAME,
  version: VERSION,
  link: ApolloLink.from([cleanTypenameLink, sentryMiddleware, authLink.concat(linkChain)]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          me: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          // getMyPermissionsPaginated: {
          //   read(existing, { args }) {
          //     return { ...existing, items: getSliced(existing?.items, args?.skip, args?.take) };
          //   },
          //   merge: (existing = { items: [] }, incoming, options) => {
          //     if (existing.items.length > 100) {
          //       console.error(
          //         `getMyPermissionsPaginated: Items exceeding threshold (${existing.items.length} included)`,
          //       );
          //     } else if (existing.items.length > 200) {
          //       console.warn(
          //         `getMyPermissionsPaginated: Items exceeding threshold (${existing.items.length} included)`,
          //       );
          //     }

          //     return {
          //       ...incoming,
          //       items: [...existing.items, ...incoming.items].reduce(
          //         (carry: { __ref: string }[], item) => {
          //           if (!carry.some((existing) => existing.__ref === item.__ref)) {
          //             return [...carry, item];
          //           }

          //           return carry;
          //         },
          //         [],
          //       ),
          //     };
          //   },
          // },
        },
      },
    },
  }),
});

// const getSliced = <T>(items: T[] = [], skip = 0, take = 10) => {
//   return items.slice(skip, skip + take);
// };

export default client;
